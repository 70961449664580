export default {
    /* Primary colours */
    blue: '#00558B',
    darkblue: '#113D5B',
    orange: '#F08C11',
    grey:'#5c5c5c',
    dark_grey: '#343434',
    light_grey: '#b2b2b2',
    light_grey_2: '#e9e9e9',
    light_grey_3: '#8a8a8a',
    /* others */
    white: '#ffffff',
    black: '#333333',
  }
  