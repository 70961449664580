import React, { Component } from 'react'
import { MuiThemeProvider, createMuiTheme } from '@material-ui/core/styles'
import { Global, css } from '@emotion/core'
import styled from '@emotion/styled'

import Loading from './Loading'
import {initGA} from '../utils/tracking'
import Header from '../components/Header'
import RightContainer from '../connected/RightContainer'
import LeftContainer from '../connected/LeftContainer'

import '../fonts/fonts.css'

const theme = createMuiTheme({
    typography : {
        fontFamily: 'BrandonText',
        useNextVariants: true
    },
    palette: {
        primary: {
            main:  '#00558B'
        },
        secondary: {
            main: '#F08C11'
        }
    }
}) 

const globalStyles = css`

  * {
    box-sizing: border-box;
  }

  html,
  body,
  #root {
    min-height: 650px;
    height: 100%;
    width: 100%;
    margin: 0;
    padding: 0;
    font-family: 'BrandonText', sans-serif;
    font-size:16px;
    color:#333;
    text-rendering: optimizeLegibility;
    -webkit-font-smoothing: antialiased;
  }
`
const AppContainer = styled('div')`
    height: 100%;
    min-height: 100vh;
    width: 100vw;
    margin: 0;
    padding: 0;
`
const MainContainer = styled('div')`
    height: ${props => props.mainHeight};
    width:100vw;
    display: flex;
    max-width:1600px;
    margin:auto;
    overflow-x:auto;
`


class App extends Component {
    // check if the app is being viewed on mobile. Do check once here and pass down in props to other components.
    constructor() {
        super();
        this.state = {
            width: window.innerWidth
        };
    }
    
    componentDidMount() {
        window.addEventListener('resize', this.handleWindowSizeChange);
        initGA('UA-38599552-3');
    }
    
    componentWillUnmount() {
        window.removeEventListener('resize', this.handleWindowSizeChange);
    }
    
    handleWindowSizeChange = () => {
        this.setState({ width: window.innerWidth });
    };

    render() {
        const { width } = this.state
        const isMobile = width <= 800
        let mainHeight = 'calc(100% - 80px)'
        if(isMobile) {
            mainHeight = '100%'
        }
        return (
            <MuiThemeProvider theme={theme}>
                <Global styles={globalStyles}/>
                <AppContainer>
                    <Loading />
                    <Header isMobile={isMobile}/>
                    <MainContainer mainHeight={mainHeight}>
                        <LeftContainer isMobile={isMobile}/> 
                        { !isMobile &&
                            <RightContainer/>
                        }
                    </MainContainer>                  
                </AppContainer>  
            </MuiThemeProvider>
        )
    }
}

export default App
