import React, { Component } from "react";
import PropTypes from "prop-types";
import styled from "@emotion/styled";

import ResetBtn from "./reset-btn";
import DownloadMap from "./download-map";
import TweetBtn from "./tweet-btn";
import HighlightCheckboxes from './highlight-checkboxes'

const Container = styled("div")`
  display: flex;
  flex-direction: row;
`;

class ActionBtnsPanel extends Component {
  render() {
    const {
      resetAll,
      selectedRegion,
      selectedCountry,
      updateCriticalUncertainties,
      updateNeedForAction,
      criticalUncertainties,
      needForAction
    } = this.props;
    return (
      <Container>
        <HighlightCheckboxes 
            updateCriticalUncertainties={updateCriticalUncertainties}
            updateNeedForAction={updateNeedForAction}
            criticalUncertainties={criticalUncertainties}
            needForAction={needForAction}
        />
        <DownloadMap />
        <TweetBtn
          selectedRegion={selectedRegion}
          selectedCountry={selectedCountry}
        />
        <ResetBtn resetAll={resetAll} />
      </Container>
    );
  }
}

ActionBtnsPanel.propTypes = {
  resetAll: PropTypes.func.isRequired
};

export default ActionBtnsPanel;
