import { combineReducers } from 'redux'

import dataloader from './dataloader'
import plot from './plot'
import timeline from './timeline'
import viewfilter from './viewfilter'
import selections from './selections'
import map from './map'
import narratives from './narratives'

export default combineReducers({
    dataloader,
    plot,
    timeline,
    viewfilter,
    selections,
    map,
    narratives
})
