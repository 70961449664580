
import React from 'react'
import PropTypes from 'prop-types'
import styled from '@emotion/styled'
import { withStyles } from '@material-ui/core/styles'
import Button from '@material-ui/core/Button'

import theme from '../../constants/theme'
const { orange } = theme

const styles = theme => ({
    button: {
        backgroundColor: orange,
        color: 'white',
        borderRadius: 3,
        textTransform: 'capitalize',
        minWidth: '100px',
        width: '100%',
        fontWeight: 700,
        '&:hover': {
            backgroundColor: orange,
            opacity: '70%'
        }
    },
    buttonDisabled: {
        border: 'solid 1px #b2b2b2',
        backgroundColor: '#fff',
        borderRadius: 3,
        textTransform: 'capitalize',
        minWidth: '100px',
        width: '100%',
        fontWeight: 700,
    }
})

const Container = styled('div')`
    display: flex;
    flex-direction: row;
    justify-content: center;
`

const MobileNarrativeBtn = ({ classes, selectedView, data, showPlot, toggleNarrative }) => {
    const isDisabled = !data[selectedView]; 
    if(isDisabled && !showPlot) {
        toggleNarrative();
    }
    let text = 'View Narrative'
    if(!showPlot) {
        text = 'View Plot'
    }
    return (
        <Container> 
            <Button
                disabled={isDisabled}
                size="small"
                variant="outlined" 
                className={isDisabled ? classes.buttonDisabled : classes.button}
                onClick={() => toggleNarrative()}>
                    {text}
            </Button>
        </Container>
    )
}

MobileNarrativeBtn.propTypes = {
    toggleNarrative: PropTypes.func.isRequired,
    classes: PropTypes.object.isRequired
}

export default withStyles(styles)(MobileNarrativeBtn)
