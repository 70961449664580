import React from 'react'
import styled from '@emotion/styled'

import mainLogo from '../../assets/logo-large.png'
import HowToModal from '../HowToModal'
import { OutboundLink } from 'react-ga'

const Container = styled('div')`
    height: ${props => props.headerHeight};
    width:100vw;
    background-color:#00558B;
    top:0;
    left:0;
`
const InternalContainer = styled('div')`
    max-width:1600px;
    margin:auto;
    display:flex;
    align-items: center;
    background-color:#00558B;
`
const ImgContainer = styled('div')`
    padding-left:20px;
`
const H4 = styled('h4')`
    margin-left:5rem;
    color:#fff;
    font-weight: bold;
    font-size: ${props => props.titleFontSize};
`
const TitleContainer = styled('div')`
    flex: 1 1 0;
`
const HeaderTools = styled('div')`
    padding-right: 40px;
`

const Header = (props) => {
    // regular css
    let headerHeight = '80px';
    let logoHeight = '50px';
    let titleFontSize = '20px';
    let buttonRightPad = '40px';
    // mobile css
    if(props.isMobile) {
        headerHeight = '40px';
        logoHeight = '25px';
        titleFontSize = '10px';
        buttonRightPad = '20px';
    }

    return (
        <Container headerHeight={headerHeight}>
            <InternalContainer>
                <ImgContainer> 
                    <OutboundLink eventLabel="Logo" rel="noopener noreferrer" target="_blank" to={"https://www.worldenergy.org/"} ><img src={mainLogo} style={{height:logoHeight}} alt="WEC"/></OutboundLink>
                </ImgContainer>
                <TitleContainer>
                    <H4 titleFontSize={titleFontSize}>World Energy Issues Monitor </H4>
                </TitleContainer>
                <HeaderTools style={{paddingRight: buttonRightPad}}> 
                    <HowToModal isMobile={props.isMobile}/>
                </HeaderTools>            
            </InternalContainer>
        </Container>
    )
}

export default Header
