// seed reducers by grabbing all the data
import {
    plotDataLoaded
} from './plot'
import {
    timelineDataLoaded
} from './timeline'
import {
    viewfilterDataLoaded
} from './viewfilter'
import {
    selectionsDataLoaded
} from './selections'
import {
    mapDataLoaded
} from './map'
import {
    narrativeDataLoaded
} from './narratives'

import {
    DataLoader
} from '../constants/actionTypes';
const {
    GET_DATA,
    GET_DATA_SUCCESS,
    GET_DATA_FAILURE
} = DataLoader;

const root = '/data/'
const urls = ['plotdata', 'countries', 'regions', 'timeline', 'categories', 'criticaluncertainties', 'world', 'narratives']

export const loadData = () => {
    return dispatch => {
        dispatch(getData())
        
        Promise.all(urls.map(url =>
            fetch(`${root}${url}.json`)
                .then(res => res.json())
                .catch(error => dispatch(getDataFailure(error)))
            ))
            .then(data => {
                dispatch(plotDataLoaded(data[0]))
                dispatch(viewfilterDataLoaded({countries: data[1], regions: data[2]}))
                dispatch(timelineDataLoaded(data[3]))
                dispatch(selectionsDataLoaded({data: data[4], criticalNeeds2019:data[5]}))
                dispatch(mapDataLoaded(data[6]))
                dispatch(narrativeDataLoaded(data[7]))
                dispatch(getDataSuccess())
            })
            .catch(error => dispatch(getDataFailure(error)))
    };
}

export const getData = () => ({
    type: GET_DATA,
})
export const getDataSuccess = payload => ({
    type: GET_DATA_SUCCESS,
    payload,
})
export const getDataFailure = payload => ({
    type: GET_DATA_FAILURE,
    payload,
})