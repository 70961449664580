import React from "react";
import PropTypes from "prop-types";
import styled from "@emotion/styled";
import { withStyles } from "@material-ui/core/styles";
import Switch from "@material-ui/core/Switch";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import { gaEvent } from '../../utils/tracking'

import theme from '../../constants/theme'
const { orange, blue } = theme

const styles = {
  root: {
    margin: "0 5px",
    padding: 0
  },
  colorSwitchBase: {
    height: 'auto',
    '&$colorChecked': {
      color: orange,
      '& + $colorBar': {
        backgroundColor: orange
      }
    }
  },
  colorSwitchBase2: {
    height: 'auto',
    '&$colorChecked': {
      color: blue,
      '& + $colorBar': {
        backgroundColor: blue
      }
    }
  },
  colorBar: {},
  colorChecked: {},
};

const Container = styled("div")`
  display: flex;
  flex-direction: row;
  flex-grow: 2;
  align-items: center;
  padding-left: ${props => props.padding}
`;

const HighlightCheckbox = ({ colorSwitchBase, colorChecked, colorBar, label, show, click}) => {
  return (
    <FormControlLabel
      control={
        <Switch
          classes={{
            switchBase: colorSwitchBase,
            checked: colorChecked,
            bar: colorBar,
          }}
          checked={show}
          onChange={click}
          disableRipple={true}
        />
      }
      label={label}
    />
  );
};

const HighlightCheckboxes = ({
  criticalUncertainties,
  needForAction,
  updateCriticalUncertainties,
  updateNeedForAction,
  classes,
  isMobile
}) => {
  let padding = 0;
  if(!isMobile) {
    padding = '60px';
  }
  return (
    <Container padding={padding}>
        <HighlightCheckbox
          colorSwitchBase={classes.colorSwitchBase}
          colorChecked={classes.colorChecked}
          colorBar={classes.colorBar}
          label={"Critical Uncertainties"}
          show={criticalUncertainties}
          click={() => {
            gaEvent('Highlight', 'Uncertainties')
            updateCriticalUncertainties()
          }}
        />
        <HighlightCheckbox
          colorSwitchBase={classes.colorSwitchBase2}
          colorChecked={classes.colorChecked}
          colorBar={classes.colorBar}
          label={"Action Priorities"}
          show={needForAction}
          click={() => {
            gaEvent('Highlight', 'Priorities')
            updateNeedForAction()
          }}
        />
    </Container>
  );
};

HighlightCheckboxes.propTypes = {
  criticalUncertainties: PropTypes.bool.isRequired,
  needForAction: PropTypes.bool.isRequired,
  classes: PropTypes.object.isRequired
};

export default withStyles(styles)(HighlightCheckboxes);
