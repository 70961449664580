
import React from 'react'
import PropTypes from 'prop-types'
import classNames from 'classnames'
import html2canvas from 'html2canvas'
import FileSaver from 'file-saver'
import CanvasToBlob from 'canvas-toBlob'
import { withStyles } from '@material-ui/core/styles'
import Button from '@material-ui/core/Button'
import CloudIcon from '@material-ui/icons/CloudDownload'
import { gaEvent } from '../../utils/tracking'

const styles = theme => ({
    button: {
        textTransform: 'capitalize',
        height: 30
    },
    leftIcon: {
        marginRight: theme.spacing.unit,
    },
    iconSmall: {
        fontSize: 20,
    }
})

const DownloadMap = ({ classes }) => {
    const downloadPNG = () => {
        gaEvent('General', 'Download')
        html2canvas(document.querySelector("#print-me"), {
            allowTaint: true,
            letterRendering: true,
            scale: window.devicePixelRatio
        }).then((canvas) => {
            if (CanvasToBlob) {
                canvas.toBlob(blob => {
                    FileSaver.saveAs(blob, "issues.png");
                })
            }
        })
    }

    return (
        <div>
            <Button
                size="small"
                variant="outlined" 
                onClick={downloadPNG}
                className={classes.button}
            >
                <CloudIcon className={classNames(classes.leftIcon, classes.iconSmall)} />
                Download map (png)
            </Button>
        </div>
    )
}

DownloadMap.propTypes = {
    classes: PropTypes.object.isRequired
}

export default withStyles(styles)(DownloadMap)

