import React, {
    Component
} from 'react'

import styled from '@emotion/styled'

import Narratives from '../../connected/Narratives'
import ViewFilter from '../../connected/ViewFilter'
import Map from '../../connected/Map'
import Selections from '../../connected/Selections'

const Container = styled('div')`
    padding-top:20px;
    padding-right:20px;
    padding-left:10px;
    width:400px;
    display: flex;
    flex-direction: column;
`

class RightContainer extends Component {

    render() {
        if(!this.props.loaded) return null
        return (
            <Container> 
                <ViewFilter />
                <Narratives />
                <Map />
                <Selections />
            </Container>
        )
    }
}

export default RightContainer