import React, {
    Component
} from 'react'
import { withStyles } from '@material-ui/core/styles'
import Button from '@material-ui/core/Button'
import { gaEvent } from '../../utils/tracking'

import HowToDialog from './how-to-dialog'


const styles = theme => ({
    button: {
        border: 'solid 1px #eee',
        color: '#fff',
        backgroundColor: '#00558B',
        borderRadius: 3,
        textTransform: 'capitalize',
        '&:hover': {
            color:'#333',
            backgroundColor: '#eee'
        }
    },
})

class HowToModal extends Component {

    state = {
        open: true
    }

    handleClose = () => {
        this.setState({
            ...this.state,
            open: false
        })
    }

    handleToggle = () => {
        gaEvent('General', 'About')
        this.setState({
            ...this.state,
            open: !this.state.open
        })
    }

    downloadPdf = () => {
        window.open(`${process.env.PUBLIC_URL}/pdfs/2022 IM - Methodology and Definitions.pdf`)
    }

    render() {
        let fontSize
        // button font size
        if (this.props.isMobile) {
            fontSize = '10px';
        }
        const { classes } = this.props
        const { open } = this.state
        return (
            <div>
                <Button onClick={this.handleToggle} className={classes.button} style={{fontSize:fontSize}}>
                    About the tool
                </Button>
                <HowToDialog handleClose={this.handleClose} open={open} downloadPdf={this.downloadPdf}/>
            </div>
        )
    }
}

export default withStyles(styles)(HowToModal)
