import {
    connect
} from 'react-redux'
import ViewFilter from '../components/ViewFilter'

import {
    setRegionSelectedView,
    setCountrySelectedView
} from '../actions/viewfilter'

import { selectedCountriesSelector } from '../selectors/viewfilter-selector'

export default connect(state => {
    return {
        regions: state.viewfilter.regions,
        countries: selectedCountriesSelector(state),
        selectedRegion: state.viewfilter.selectedRegion,
        selectedCountry: state.viewfilter.selectedCountry,
        selectedView: state.viewfilter.selectedView
    }
}, {
    setRegionSelectedView,
    setCountrySelectedView
})(ViewFilter)