import axios from 'axios'

export function urlShortener() {
    const longURL = encodeURIComponent(window.location.href)
    console.log(longURL)
    const bitly = 'https://api-ssl.bitly.com/v3/shorten?'
    const token = process.env.REACT_APP_BITLY_TOKEN
    const url = `${bitly}access_token=${token}&longUrl=${longURL}`
    return axios({
            method: 'get',
            url
        })
        .then(res => res.data)
        .catch(e => {
            console.log('error, ' + e);
            return false
        }) // link this to surfacing an error
}