import React, {
    PureComponent
} from 'react'
import styled from '@emotion/styled'
import {gaEvent} from '../../utils/tracking'

import IssuesChart from '../Chart'
import PlotTitle from './plot-title'

const Container = styled('div')`
    height: 100%;
    width: 100%;
    display: flex;
    flex-direction: column;
    background-color: #fff;
`
const PlotChartContainer = styled('div')`
    height: calc(100% - 50px);
    min-height: 200px;
    width: 100%;
    display: flex;
    flex-direction: column;
`
const PlotInfoContainer = styled('div')`
    display: flex;
    flex-direction: row;
    flex: 1 1 auto;
    text-align: ${props => props.textAlign};
`

class Plot extends PureComponent {

    render() {
        const { 
            data,
            selectedData,
            lineData,
            selectedView,
            uniqueYears,
            highlighted,
            updateHighlighted,
            criticalUncertainties,
            needForAction,
            isMobile
        } = this.props

        let textAlign = 'left'
        if(isMobile) {
            textAlign = 'center'
        }
        return (
            <Container id="print-me" >
                <PlotChartContainer>
                    { data && 
                        <IssuesChart
                            data={data}
                            lineData={lineData}
                            selectedData={selectedData}
                            updateHighlighted={(d) => {
                                gaEvent('Monitor', 'Issue', d)
                                updateHighlighted(d)
                            }}
                            highlighted={highlighted}
                            years={uniqueYears}
                            criticalUncertainties={criticalUncertainties}
                            needForAction={needForAction}
                            isMobile={isMobile}
                        /> 
                    }
                </PlotChartContainer>
                <PlotInfoContainer textAlign={textAlign}> 
                    { data && <PlotTitle size={data.length} years={uniqueYears} view={selectedView} isMobile={isMobile}/> }
                </PlotInfoContainer>
                
            </Container>
        )

    }
}

export default Plot