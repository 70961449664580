import { Selections } from '../constants/actionTypes';

const {
    SELECTIONS_DATA_LOADED,
    UPDATE_HIGHLIGHTED,
    RESET_HIGHLIGHTED,
    TOGGLE_ALL_CATEGORY,
    UPDATE_CRITICAL_UNCERTAINTIES,
    UPDATE_NEED_FOR_ACTION
} = Selections;

export const selectionsDataLoaded = payload => ({
    type: SELECTIONS_DATA_LOADED,
    payload,
});

export const updateHighlighted = payload => ({
    type: UPDATE_HIGHLIGHTED,
    payload: payload,
});

export const resetHighlighted = payload => ({
    type: RESET_HIGHLIGHTED,
    payload,
}); 

export const toggleAllCategory = payload => ({
    type: TOGGLE_ALL_CATEGORY,
    payload,
});

export const updateCriticalUncertainties = payload => ({
    type: UPDATE_CRITICAL_UNCERTAINTIES,
    payload,
});

export const updateNeedForAction = payload => ({
    type: UPDATE_NEED_FOR_ACTION,
    payload,
});
