import React from 'react'
import DialogTitle from '@material-ui/core/DialogTitle';
import Dialog from '@material-ui/core/Dialog';
import styled from '@emotion/styled'
import { withStyles } from '@material-ui/core/styles'
import Button from '@material-ui/core/Button'

const DialogTitleContainer = styled(DialogTitle)`
    text-align: center;
    padding-top:0!important;
`
const BtnContainer = styled('div')`
    display: flex;
    justify-content: center;
    align-content: center;
`
const NarrativeText = styled('div')`
    font-weight: 400;
    font-size: 14px;
    color: #333;
`
const styles = theme => ({
    button: {
        border: 'solid 1px #b2b2b2',
        backgroundColor: '#fff',
        borderRadius: 3,
        textTransform: 'capitalize',
        '&:hover': {
            backgroundColor: '#eee'
        }
    },
})

const NarrativeDialog = ({ handleClose, downloadNarrative, selectedNarrative, selectedViewPlace, classes, ...other}) => {
    const click = () => downloadNarrative(pdf)
    const { excerpt, pdf } = selectedNarrative
    const isBtnDisabled = pdf === ''
    const addNewlines = (excerpt) => {
        const lines = excerpt.split('\n,')
        return lines.map(d => <p key={d}>{d}</p> );
    }
    return (
        <Dialog onClose={handleClose} aria-labelledby="simple-dialog-title" {...other}>
            <DialogTitle id="simple-dialog-title-narrative">{selectedViewPlace} Narrative</DialogTitle>
            <DialogTitleContainer>
                <NarrativeText>{addNewlines(excerpt)}</NarrativeText>
                <BtnContainer>
                    <Button disabled={isBtnDisabled} onClick={click} className={classes.button}>Download Full Narrative here</Button>
                </BtnContainer>
            </DialogTitleContainer>
        </Dialog>
    )
}

export default withStyles(styles)(NarrativeDialog)