import React, {
    PureComponent,
    Fragment
} from 'react'
import { select } from 'd3-selection'

const duration = 2000
const textHeight = 22 // height of div for calculating overlapping labels

class SelectedCirclesText extends PureComponent {

    state = {
        div: null,
    }

    onRef = ref => {
        const { width, height } = this.props
        const g = select(ref).append('svg').attr('width', width).attr('height', height)
        this.setState({ div: select(ref), g }, () => this.renderText())
    }

    componentDidUpdate = () => {this.renderText();this.renderText()} // hack to make the below work

    renderText = () => {
        const { div, g } = this.state
        const { data, scales:{ xScale, yScale, rSize }, width, height} = this.props

        const labelArray = data.map( d => {
            const { I, U } = d
            return {
                x: xScale(I),
                y: yScale(U),
                r: rSize,
                x1: xScale(I),
                y1: yScale(U),
                dy : (-rSize - 3),
                width:0,
                height:0,
                ...d
            }
        })
        const anchorArray = labelArray.map( d => {
            const { x, y, r, } = d
            return {
                x, y, r
            }
        })

        // rejoin the data and add the issues
        const labels = div.selectAll('.issues-selected-text')
            .data(labelArray, d => d.id);
        const links = g.selectAll('.issues-selected-links')
            .data(labelArray, d => d.id);
        
        // add any new elements
        labels
            .enter()       
        .append('div')
            .classed('issues-selected-text', true)
            .style('position', 'absolute')
            .style('display', 'flex')
            .style('font-family', 'BrandonText')
            .style('text-shadow', '-1px -1px 0 #fff, 1px -1px 0 #fff, -1px 1px 0 #fff,  1px 1px 0 #fff')
            .style('font-size', '14px')
            .style('font-weight', 'bold')
            .style("left", d => `${d.x}px`)
            .style("top", d => `${d.y}px`)
            .style("margin-top", `${-(textHeight/2)}px`)
            .style("line-height", '14px')
            .text(d => d.name)

        links
            .enter()       
        .append('line')
            .classed('issues-selected-links', true)
            .attr('x1', d => d.x)
            .attr('y1', d => d.y)
            .attr('x2', d => d.x)
            .attr('y2', d => d.y)
            .attr("stroke-width", 0.3)
            .attr("stroke", "#000");
        
        // remove anything that shouldnt be there
        labels
            .exit()
            .remove()
        links
            .exit()
            .remove()

        // now calculate the width and the height
        labels.each(function(f,i) {
            // need to keep 'this' related
            labelArray[i].width = this.getBoundingClientRect().width
            labelArray[i].height = textHeight
        })

        // followed this guide to prevent labels from overlapping 
        // https://medium.com/@adarshlilha/removing-label-overlapping-from-pretty-charts-8dd2e3581b71
        window.labeler()
            .label(labelArray)
            .anchor(anchorArray)
            .width(width)
            .height(height)
            .start(duration);
   
        // UPDATE CYCLE
        labels
            .transition()
            .duration(duration)
            .style("left", d => `${d.x}px`)
            .style("top", d => `${d.y}px`)
        
        links
            .attr('x1', d => d.x1)
            .attr('y1', d => d.y1)
            .transition()
            .duration(duration)
            .attr("x2", d => d.x)
            .attr("y2", d => d.y)
    }

    render() {
        const { width, height } = this.props
        return (
            <Fragment>
                <div style={{width, height}} ref={this.onRef} ></div>
            </Fragment>
        )
    }
}

export default SelectedCirclesText