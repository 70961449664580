import React, { PureComponent } from 'react'
import PropTypes from 'prop-types'
import styled from '@emotion/styled'

import theme from '../../constants/theme'
const { orange, blue } = theme

const Container = styled('div')`
    position: absolute;
    pointer-events: none;
    width:${props=>props.width}px;
    height: ${props=>props.height}px;
`

class Annotations extends PureComponent {

    getAnnotations = annotationList => {
        return annotationList.map( (item, i) => {
            const { text, name, x, y, rotate, fontSize, fill } = item
            return (
                <div
                    key={i}
                    className={name}
                    style={{
                        left:x,
                        top:y,
                        fontSize,
                        color:fill,
                        fontFamily:'BrandonText',
                        position:'absolute',
                        transform: `rotate(${rotate}deg)`,
                        transformOrigin: 'top left',
                        display: 'flex'
                    }}
                >
                    { text }
                </div>
            )
        })
    }
    render() {
        const { margins, height, width, criticalUncertainties, needForAction } = this.props
        const annotationList = [
            {
                text: 'Impact',
                name: 'axis-labels',
                x: width - margins.left - margins.right,
                y: height - 15,
                fontSize: '14px',
                rotate: 0,
                anchor: 'start',
                fill: '#333'
            },
            {
                text: 'Uncertainty',
                name: 'axis-labels',
                fontSize: '14px',
                x: 30,
                y: 95,
                rotate: -90,
                anchor: 'start',
                fill: '#333'
            },
            {
                text: 'Weak Signals',
                name: 'chart-labels',
                fontSize: '18px',
                x: 70,
                y: -(margins.top - height) - 20,
                rotate: 0,
                anchor: 'start',
                fill: '#b2b2b2'
            },
            {
                text: 'Critical Uncertainties',
                name: 'chart-labels',
                fontSize: '18px',
                x: (width - margins.left - margins.right) - 105,
                y: 5,
                rotate: 0,
                anchor: 'end',
                fill: criticalUncertainties? orange:'#b2b2b2'
            },
            {
                text: 'Need for Action',
                name: 'chart-labels',
                fontSize: '18px',
                x: (width - margins.left - margins.right) - 85,
                y: -(margins.top - height ) - 20,
                rotate: 0,
                anchor: 'end',
                fill: needForAction? blue:'#b2b2b2'
            },
        ]
        return (
            <Container width={width} height={height}>
                { this.getAnnotations(annotationList) }
            </Container>
        )
    }
}

Annotations.propTypes = {
    margins: PropTypes.object.isRequired,
    height: PropTypes.number.isRequired,
    width: PropTypes.number.isRequired, 
    criticalUncertainties: PropTypes.bool.isRequired,
    needForAction: PropTypes.bool.isRequired   
}

export default Annotations