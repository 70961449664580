import {
    connect
} from 'react-redux'
import Plot from '../components/Plot'
import { updateHighlighted } from '../actions/selections'
 
import {
    pointsSelector,
    selectedPointsSelector,
    uniqueYearsSelector,
    selectedLineSelector
} from '../selectors/plot-selector'

import {
    viewSelector
} from '../selectors/viewfilter-selector'

export default connect(state => {
    return {
        data: pointsSelector(state),
        selectedData: selectedPointsSelector(state),
        lineData: selectedLineSelector(state),
        selectedView: viewSelector(state),
        selectedYears: state.timeline.selectedYears,
        uniqueYears: uniqueYearsSelector(state),
        highlighted: state.selections.highlighted,
        criticalUncertainties: state.selections.criticalUncertainties,
        needForAction: state.selections.needForAction,
    }
}, {
    updateHighlighted
})(Plot)