import React from 'react'

import styled from '@emotion/styled'

import ViewFilter from '../../connected/ViewFilter'

const Container = styled('div')`
    padding-top:20px;
    padding-right:20px;
    padding-left:10px;
    display: flex;
    flex-direction: row;
    flex-grow: 1;
    justify-content: space-between;
    align-items: flex-end;
`

export default function MobileFilters() {
    return (
            <Container> 
                <ViewFilter/>
            </Container>
        )
}