import React from 'react'
import styled from '@emotion/styled'

import { min, max } from 'd3-array'
import Slider from 'rc-slider'

import 'rc-slider/assets/index.css'


const Range = Slider.Range
const RangeContainer = styled(Range)`
    margin-top:15px;
    margin-bottom: 20px;

    & .rc-slider-rail {
        height: 12px;
        border-radius: 0px;
    }
    & .rc-slider-handle {
        border: 0;
        border-radius: 3px;
        background-color: #000;
        width: 7px;
        height: 22px;
    }
    & .rc-slider-track {
        height: 12px;
        margin-left: -3px;
        border-radius: 0px;
        background-color:#00558B;
    }
    & .rc-slider-dot {
        bottom: inherit;
        width: 5px;
        height: 12px;
        border: none;
        background-color: #fff;
        border-radius: 0;
    }
    & .rc-slider-dot-active {
        display:none;
    }
    & .rc-slider-handle:active, .rc-slider-handle:focus {
        box-shadow: none;
    }
    & .rc-slider-step {
        height: 12px;
    }
    & .rc-slider-mark-text {
        -webkit-touch-callout: none; 
        -webkit-user-select: none; 
        -khtml-user-select: none; 
        -moz-user-select: none; 
            -ms-user-select: none; 
                user-select: none; 
    }
`
const Timeslider = ({ data, selectedYears, update }) => {
    
    const minVal = min(data)
    const maxVal = max(data)

    const marks = Object.assign(...data.map( v => ({[v]: v.toString()})))

    const onChange = d => update(d)

    return (
        <RangeContainer
            allowCross={true}
            value={selectedYears}
            onChange={onChange}
            min={minVal}
            max={maxVal}
            step={1}
            marks={marks}
        />
    )
}

export default Timeslider
