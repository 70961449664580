import React from 'react'
import DialogTitle from '@material-ui/core/DialogTitle';
import Dialog from '@material-ui/core/Dialog';
import styled from '@emotion/styled'
import { withStyles } from '@material-ui/core/styles'
import Button from '@material-ui/core/Button'

import theme from '../../constants/theme'
const { orange } = theme

const DialogTitleContainer = styled(DialogTitle)`
    text-align: center;
`
const DialogContentContainer = styled('div')`
    padding:0px 20px 20px 20px;
    max-height: calc(100% - 100px);
    & li {
        font-size: 14px;
        font-weight: 400;
        color: #333333;
    }
`
const H5 = styled('h5')`
    font-weight:bold;
    margin-bottom:2px;
    font-size: 16px;
    color: #333;
    padding-right: 15px;
`
const BtnContainer = styled('div')`
    display: flex;
    justify-content: center;
    align-content: center;
    margin-top: 1rem;
`
const P = styled('p')`
    font-weight: 400;
    font-size: 14px;
    color: #333;
`

const styles = theme => ({
    button: {
        border: 'solid 1px #b2b2b2',
        backgroundColor: '#fff',
        borderRadius: 3,
        textTransform: 'capitalize',
        '&:hover': {
            backgroundColor: '#eee'
        }
    },
    link: {
        color: orange,
        display: 'inline-block',
        '&:hover': {
            cursor: 'pointer'
        }
    },
})

const HowToDialog = ({ handleClose, classes, downloadPdf, ...other}) => {
    return (
        <Dialog onClose={handleClose} aria-labelledby="simple-dialog-title" {...other}>
            <DialogTitleContainer id="simple-dialog-title">About the World Energy Issues Monitor</DialogTitleContainer>
            <DialogContentContainer>
                <P>
                    The World Energy Issues Monitor is the World Energy Council’s annual survey of key challenges and opportunities faced by energy leaders around the world in managing and shaping energy transitions. The monitor helps to define the world energy agenda and its evolution over time. It provides a high-level perception of what constitute issues of critical uncertainty, in contrast to those that require immediate action or act as developing signals for the future. It is an essential tool for understanding the complex and uncertain environment in which energy leaders must operate, and a tool which challenges one’s own assumptions on the key drivers within the energy landscape. The full report can be read at 
                    <a href="https://www.worldenergy.org/publications" rel="noopener noreferrer" target="_blank" className={classes.link}>https://www.worldenergy.org/publications</a> .
                </P>
                <H5>About the Interactive Tool</H5>
                <P>
                    The World Energy Issues Monitor interactive online tool is developed in cooperation with the World Energy Council’s Partner, Arup. The Tool presents in one place dynamic map views of the Issues Monitor data that has been collated by the World Energy Council. The maps convey a narrative of the key energy issues, regional and local variances and how these have changed over time. The tool allows the preparation of different maps for comparison and allows for the manipulation of data by geography, over time, or by highlighting specific energy issues.
                </P>
                <H5>How to Read the Monitor</H5>
                <P></P>
                <ul>
                    <li>
                        <span style={{'fontWeight':'bold'}}>Critical Uncertainties: </span> 
                        issues perceived with high uncertainty and high impact, which appear in energy leaders’ 
                        radar as areas of concern (top-right quadrant)
                    </li>
                    <li>
                        <span style={{'fontWeight':'bold'}}>Action Priorities: </span> 
                        areas of low uncertainty and high impact where countries are acting pragmatically to progress 
                        in their energy transition (bottom-right quadrant)
                    </li>
                    <li>
                        <span style={{'fontWeight':'bold'}}>Weak Signals: </span> 
                        Issues with low uncertainty and low impact. These are perceived with reduced importance or are 
                        still not fully understood and in need of further investigation (bottom-left quadrant)
                    </li>
                </ul>

                <div onClick={downloadPdf} className={classes.link}><u>Download Methodology and Issues' Definitions</u></div>

                <BtnContainer>
                    <Button onClick={handleClose} className={classes.button}>Got it</Button>
                </BtnContainer>
            </DialogContentContainer>

        </Dialog>
    )
}

export default withStyles(styles)(HowToDialog)