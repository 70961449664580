import React from 'react'
import styled from '@emotion/styled'

const Container = styled('div')`
    position: absolute;
    left: 0;
    top: 0;
    pointer-events: none;
    color: #999;
    display:flex;
    font-family: 'BrandonText';
    user-select: none;
    font-size:24px;
    width: ${props => props.width}px;
    height: ${props => props.height}px;
    display:flex;
    align-items: center;
    justify-content: center;
    font-weight: bold;
`
const InnerContainer = styled('div')`
    background-color:#fff;
`

const NoDataDiv = ({year, width, height}) => {
    return (
        <Container width={width} height={height}> <InnerContainer>No data for {year} </InnerContainer> </Container>
    )
}

export default NoDataDiv;
