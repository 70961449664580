import React, {
    PureComponent
} from 'react'
import PropTypes from 'prop-types'
import { select } from 'd3-selection'
import { transition } from 'd3-transition'
import { easeCubicOut } from 'd3-ease'

class Circles extends PureComponent {

    state = {
        g: null
    }

    onRef = ref => {
        this.setState({ g: select(ref) }, () => this.renderCircles())
    }

    componentDidUpdate = () => this.renderCircles()

    click = ({ id }) => this.props.updateHighlighted(id)

    renderCircles = () => {
        const { g } = this.state
        const { data, highlighted, onMouseOver, onMouseOut, scales:{ xScale, yScale, rSize }} = this.props

        const isSomethingHighlighted = Object.keys(highlighted).map((k) => highlighted[k]).filter(d => d)

        const t = transition()
            .duration(1000)
            .ease(easeCubicOut)
        
        // rejoin the data and add the issues
        const issuesCircles = g.selectAll('.issues-circles')
            .data(data, d => d.id)
        
        issuesCircles
            .enter()
        .append('circle')
            .classed('issues-circles', true)
            .attr('cx', d => xScale(d.I))
            .attr('cy', d => yScale(-10))
            .attr('stroke', '#fff')
            .attr('stroke-width', '1.5px')
            .style('cursor', 'pointer')
            .on('click', this.click)
            .on('mouseover', d=> onMouseOver(d))
            .on('mouseout', d=> onMouseOut(d))

        // update the elements
        g.selectAll('.issues-circles').transition(t).delay((d, i) => i * 20)            
            .attr('fill', d => highlighted[d.id] ? 'transparent' : '#b2b2b2' )
            .attr('fill-opacity', d => isSomethingHighlighted.length ===0 ? 0.7 : 0.4)
            .attr('cx', d => xScale(d.I))
            .attr('cy', d => yScale(d.U))
            .attr('r', d => rSize)

        // remove anything that shouldnt be there
        issuesCircles
            .exit()
            .remove()

    }

    render() {
        return (
            <g ref={this.onRef} ></g>
        )
    }
}

Circles.propTypes = {
    updateHighlighted: PropTypes.func.isRequired,
    onMouseOver: PropTypes.func.isRequired,
    onMouseOut: PropTypes.func.isRequired,
    highlighted: PropTypes.object.isRequired,
    data: PropTypes.array.isRequired,
    scales: PropTypes.object.isRequired,
}

export default Circles


