import { Timeline } from '../constants/actionTypes';

const {
    TIMELINE_DATA_LOADED,
    UPDATE_SELECTED_YEARS
} = Timeline;

export const timelineDataLoaded = payload => ({
    type: TIMELINE_DATA_LOADED,
    payload,
});

export const updateSelectedYears = payload => ({
    type: UPDATE_SELECTED_YEARS,
    payload,
});