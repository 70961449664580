import React, {
    PureComponent
} from 'react'
import styled from '@emotion/styled'

const Container = styled('div')`
    position: absolute;
    pointer-events: none;
    color: #5c5c5c;
    display:flex;
    font-family: 'BrandonText';
    user-select: none;
    font-size:14px;
    text-shadow:-1px -1px 0 #fff,
        1px -1px 0 #fff,
        -1px 1px 0 #fff,
        1px 1px 0 #fff; 
`

const textHeight = 22

class Tooltip extends PureComponent {

    render() {
        const { scales:{ xScale, yScale}, target } = this.props
        return (
            <Container 
                style={{
                    left: `${xScale(target.I)}px`,
                    top: `${yScale(target.U)}px`,
                    marginTop: `${-(textHeight/2)}px`
                }}
            >
                {target.name}
            </Container>
        )
    }
}

export default Tooltip;
