import ReduxQuerySync from 'redux-query-sync'

import { updateHighlighted, updateCriticalUncertainties, updateNeedForAction } from '../actions/selections'
import { setRegionSelectedView, setCountrySelectedView } from '../actions/viewfilter'
import { updateSelectedYears } from '../actions/timeline'

import config from '../config'
const defaultYears = config.timeline.years

const urlParamManager = ReduxQuerySync.enhancer({
    params: {
        highlighted: {
            // The selector used to get the destination string from the state object.
            selector: state => validateObject(state.selections.highlighted),
            // The action creator for setting a new destination
            action: value => updateHighlighted(value),
            // Cast the parameter value (we map invalid values, which will then
            // hide the parameter).
            stringToValue: string => string ? string.split(',') : '',
            // We then also specify the inverse function (this example one is the default)
            valueToString: value => `${value}`,
            // When state equals default equals 1, the parameter is hidden (and vice versa)
            defaultValue: ''
        },
        selectedRegion: {
            selector: state => state.viewfilter.selectedRegion,
            action: value => setRegionSelectedView(value),
            stringToValue: string => string || 'Global',
            valueToString: value => `${value}`,
            defaultValue: 'Global'
        },
        selectedCountry: {
            selector: state => state.viewfilter.selectedCountry,
            action: value => setCountrySelectedView(value),
            stringToValue: string => string || 'None',
            valueToString: value => `${value}`,
            defaultValue: 'None'
        },
        selectedYears: {
            selector: state => state.timeline.selectedYears.join(','),
            action: value => updateSelectedYears(value.map(f => +f)),
            stringToValue: string => string ? string.split(',') : defaultYears,
            valueToString: value => `${value}`,
            defaultValue: defaultYears.join(',')
        },
        critical: {
            selector: state => state.selections.criticalUncertainties,
            action: value => updateCriticalUncertainties(value),
            stringToValue: string => string ==='true'? true:false,
            valueToString: value => value? 'true':'false',
            defaultValue: true
        },
        needs: {
            selector: state => state.selections.needForAction,
            action: value => updateNeedForAction(value),
            stringToValue: string => string ==='true'? true:false,
            valueToString: value => value? 'true':'false',
            defaultValue: true
        }
    },
    initialTruth: 'location',
    replaceState: true,
})

export default urlParamManager

// highlighted params
const validateObject = value => !objectIsEmpty(value) ? objectToString(value) : ''
const objectToString = value => Object.keys(value).filter( f => value[f]).join(',') 
const objectIsEmpty = obj => Object.keys(obj).length === 0 && obj.constructor === Object