import React, { PureComponent } from 'react'
import PropTypes from 'prop-types'

class Axes extends PureComponent {

  render() {
      const { margins, height, width, scales } = this.props
      const { xScale, yScale  } = scales
        return (
            <g> 
                <line className="y-axis" stroke={'#e9e9e9'} x1={margins.left} x2={margins.left} y1={margins.top} y2={height - margins.bottom} />                
                <line className="x-axis" stroke={'#e9e9e9'} x1={margins.left} x2={width - margins.left} y1={height - margins.bottom} y2={height - margins.bottom} />
                <line className="y-middle-axis" stroke={'#e9e9e9'} x1={xScale(0)} x2={xScale(0)} y1={margins.top} y2={height - margins.bottom} />
                <line className="x-middle-axis" stroke={'#e9e9e9'} x1={margins.left} x2={width - margins.left } y1={yScale(0) } y2={yScale(0)} />
            </g>
        )
  }
}

Axes.propTypes = {
    scales: PropTypes.object.isRequired,
    margins: PropTypes.object.isRequired,
    height: PropTypes.number.isRequired,
    width: PropTypes.number.isRequired
}

export default Axes