import {
    updateSelectedYears
} from './timeline'
import {
    setRegionSelectedView
} from './viewfilter'
import {
    resetHighlighted
} from './selections'

import config from '../config'
const {
    years
} = config.timeline

export const resetAll = () => {
    return dispatch => {
        dispatch(updateSelectedYears(years))
        dispatch(setRegionSelectedView('Global'))
        dispatch(resetHighlighted())
    }
}
