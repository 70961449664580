import { connect } from 'react-redux'
import Selections from '../components/Selections'

import { updateHighlighted, toggleAllCategory } from '../actions/selections'
 
import {
    selectedCountriesSelector
} from '../selectors/selections-selector'

import {
    uniqueCategoriesSelector
} from '../selectors/plot-selector'

export default connect(state => {
    return {
        data: selectedCountriesSelector(state),
        highlighted: state.selections.highlighted,
        uniqueCategories: uniqueCategoriesSelector(state),
        categoryKeys: state.selections.categoryKeys
    };
}, {
    updateHighlighted,
    toggleAllCategory
})(Selections)
