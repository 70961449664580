import { connect } from 'react-redux'
import Timeline from '../components/Timeline'
import { updateSelectedYears } from '../actions/timeline'
import { resetHighlighted } from '../actions/selections'

export default connect(state => {
    return {
        selectedYears: state.timeline.selectedYears,
        data: state.timeline.data,
    };
}, {
    updateSelectedYears,
    resetHighlighted
})(Timeline)
