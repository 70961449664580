import {
  connect
} from 'react-redux'
import MobileNarrative from '../components/MobileNarrative'
import { selectedCountriesSelector } from '../selectors/narratives-selector'

export default connect(state => {
  return {
      selectedView: state.viewfilter.selectedView,
      data: selectedCountriesSelector(state)
  }
}, {})(MobileNarrative)