
import React from 'react'
import styled from '@emotion/styled'
import { withStyles } from '@material-ui/core/styles'
import Button from '@material-ui/core/Button'
import { gaEvent } from '../../utils/tracking'

import ShareIcon from '../../assets/twitter'
import { urlShortener } from '../../utils/bitly-util'

const Container = styled('div')`
    margin-left:10px;
`
const styles = theme => ({
    button: {
        textTransform: 'capitalize',
        height: 30
    }
})

const TweetBtn = ({selectedRegion, classes}) => {

    const tweetURL = () => {
        gaEvent('General', 'Tweet')
        urlShortener()
            .then( res => {
                if(res && res.status_txt === 'OK') {
                    const { data } = res
                    const { url } = data
                    const twitterText = encodeURIComponent(`Explore my customised ${selectedRegion} Energy #IssuesMonitor map from the @WECouncil online #Toolkit`)
                    const twitterLink = `https://twitter.com/share?url=${url}&text=${twitterText}`
                    window.open(twitterLink, "_blank")
                }
                
            })
            .catch( err => console.log('error trying to tweet: ', err)) // Link to surfacing and error
    }

    return (
        <Container>
            <Button 
                size="small"
                variant="outlined" 
                onClick={tweetURL}
                className={classes.button}
            >
                <ShareIcon/>
                Tweet view
            </Button> 
        </Container>
    )
}

export default withStyles(styles)(TweetBtn)
