import React from 'react'
import styled from '@emotion/styled'
import PlayIcon from '@material-ui/icons/PlayArrow';
import StopIcon from '@material-ui/icons/Stop';
import Fab from '@material-ui/core/Fab';

const Container = styled('div')`
    margin-right:15px;
    margin-top:7px;
`

const PlayButton = ({ play, updatePlay }) => {
    return (
        <Container>
            <Fab size="small" color="primary" aria-label="Play" onClick={updatePlay}>
                { play? <StopIcon fontSize="small" /> : <PlayIcon fontSize="small" />}
            </Fab>
        </Container>
    )
}

export default PlayButton
