import React, { PureComponent } from "react";
import styled from "@emotion/styled";
import CircularProgress from "@material-ui/core/CircularProgress";

const Container = styled("div")`
  position: absolute;
  height: 100%;
  width: 100%;
  background-color: rgba(0, 0, 0, 0.6);
  z-index: 1002;
  display: flex;
  justify-content: center;
  align-items: center;
`

class Loading extends PureComponent {
  renderProgress = () => {
    return (
      <Container>
        <CircularProgress style={{ color: "#F08C11" }} />
      </Container>
    );
  };

  render() {
    const { isLoading } = this.props;
    return (
        <>
          {isLoading && this.renderProgress()}
        </>
    )
        
  }
}

export default Loading;
