import React, { Component } from "react";
import styled from '@emotion/styled'

import CategoryPanel from "./CategoryPanel";

const Container = styled('div')`
  border-top: solid 1px #e9e9e9;    
  height:100%;
  width:100%;
  flex: 1 1 auto;
  min-height: 0;
  overflow-y: auto;
  overflow-x: hidden;
`
const AdditionalText = styled('p')`
  padding: 0 10px;
  font-size: 0.6875rem;
  text-align: justify;
`

class Selections extends Component {
  downloadPdf = () => {
    window.open(`${process.env.PUBLIC_URL}/pdfs/Issues Definitions.pdf`)
  }

  render() {
    const {
      data,
      highlighted,
      uniqueCategories,
      updateHighlighted,
      toggleAllCategory,
      categoryKeys
    } = this.props;
    return (
      <Container>
        {data.map(category => (
          <CategoryPanel
            toggleAllCategory={toggleAllCategory}
            updateHighlighted={updateHighlighted}
            highlighted={highlighted}
            uniqueCategories={uniqueCategories}
            key={category.key}
            data={category}
            categoryKeys={categoryKeys}
          />
        ))}
        <AdditionalText>*In the 2022 results, the issue “Quality Energy Access” replaces “(basic) Energy Access” to include productive uses of energy.</AdditionalText>
      </Container>
    );
  }
}

export default Selections;
