// x is 'I' (impact) and y is 'U' (uncertainty)

const getDistance =(p1, p2) => Math.sqrt(Math.pow(p1.I - p2.I, 2) + Math.pow(p1.U - p2.U, 2))

export function findTop5Critical(data, target) {
    return findTop5(data, target)
}

export function findTop5Needs(data, target) {
    return findTop5(data, target)
}

function findTop5(data, target) {
    if(data.length>0) {
        const distances = data.map(f=>({id:f.id.toLowerCase(), distance:getDistance(f,target)}))
            .sort( (x, y) => (x.distance - y.distance))
            .slice(0,5) // get the top 5
        return Object.assign(...distances.map(d => ({[d.id]: true})))
    } else {
        return {}
    }
}
