import React, { PureComponent } from 'react'
import styled from '@emotion/styled'
import { scaleLinear } from 'd3-scale'

import config from '../../config'
const { domains } = config
let margins = {
    top: 25,
    right: 20,
    bottom: 20,
    left: 60
}

const Container = styled('div')`
  height: 100%;
  width: 100%;
  flex: 1 1 auto;
`

// NB from https://github.com/caspg/badass-chart/tree/master/src/components
export default ChartComponent => (
  class ResponsiveChart extends PureComponent {
    constructor(props) {
      super(props)

      this.state = {
        containerWidth: null,
        containerHeight: null,
        show: false,
      }

      this.fitParentContainer = this.fitParentContainer.bind(this)
    }

    componentDidMount() {
      setTimeout(() => {
        this.setState({ show: true })
        this.fitParentContainer()
        window.addEventListener('resize', this.fitParentContainer)
      }, 0)      
    }

    componentWillUnmount() {
      window.removeEventListener('resize', this.fitParentContainer)
    }

    fitParentContainer() {
      const { containerWidth, containerHeight } = this.state
      
      if(this.chartContainer && this.chartContainer.getBoundingClientRect()) {
        const currentContainerWidth = this.chartContainer
          .getBoundingClientRect().width

        const currentContainerHeight = this.chartContainer
          .getBoundingClientRect().height

        const shouldResize = (containerWidth !== currentContainerWidth) || (containerHeight !== currentContainerHeight)

        if (shouldResize) {
          this.setState({
            containerWidth: currentContainerWidth,
            containerHeight: currentContainerHeight,
          })
        }
      }
      
    }

    renderChart() {
      const width = this.state.containerWidth
      const height = this.state.containerHeight

      const scales = {
        yScale : scaleLinear().domain([domains.min,domains.max]).range([height - margins.bottom, margins.top]).clamp(true),
        xScale : scaleLinear().domain([domains.min,domains.max]).range([margins.left, width - margins.right]).clamp(true),
        rSize : 15
      }

      if(this.props.isMobile){
        margins.left = 20;
      } else {
        margins.left = 60;
      }

      return (
        <ChartComponent {...this.props} width={width} height={height} scales={scales} margins={margins}/>
      )
    }

    render() {
      const { containerWidth, containerHeight, show } = this.state
      const shouldRenderChart = containerWidth !== null && containerHeight !== null && show

      return (
        <Container
          ref={(el) => { this.chartContainer = el }}
        >
          {shouldRenderChart && this.renderChart()}
        </Container>
      )
    }
  }
)