import React from 'react'

import styled from '@emotion/styled'

import DownloadMap from '../ActionBtnsPanel/download-map'
import TweetBtn from '../ActionBtnsPanel/tweet-btn'

const Container = styled('div')`
    padding-top:20px;
    padding-right:20px;
    padding-left:10px;
    display: flex;
    flex-direction: row;
    flex-grow: 1;
    justify-content: center;
    align-items: flex-end;
`

export default function MobileSocials({selectedRegion, selectedCountry}) {
    return (
          <Container>
                <DownloadMap />
                <TweetBtn selectedRegion={selectedRegion} selectedCountry={selectedCountry}/>
          </Container>
        )
}