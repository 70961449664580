import React, {
    useState
} from 'react'

import styled from '@emotion/styled'

import { gaEvent } from '../../utils/tracking'

import ActionBtnsPanel from '../../connected/ActionBtnsPanel'
import Footer from '../Footer'
import MobileActionBtns from '../../connected/MobileActionBtns'
import MobileFilters from '../MobileFilters'
import MobileNarrative from '../../connected/MobileNarrative'
import MobileSocials from '../MobileSocials'
import Plot from '../../connected/Plot'
import Timeline from '../../connected/Timeline'
import MobileBtnsPanel from '../../connected/MobileBtnsPanel'


const Container = styled('div')`
    height:100%;
    width:  ${props => props.width};
    display: flex;
    flex-direction: column;
`

export default function LeftContainer(props){
    
        // create component state
        // manage whether plot or narrative is shown in mobile view
        const [showPlot, setShowPlot] = useState(true)

        const toggleShowPlot = () => {
            if(showPlot) {
                gaEvent('Narrative', 'Narrative')
            }
            setShowPlot(!showPlot)
        }

        const revertToPlot = () => {
            setShowPlot(true)
        }

        // determine width based on mobile
        let width = 'calc(100% - 400px)'

        // make changes for mobile
        if(props.isMobile) {
            width = '100vw'
        } else {
            if(!showPlot) {
                revertToPlot()
            }
        }

        if(!props.loaded) return null
        return (
            <Container width={width}> 
                {props.isMobile && <MobileFilters />}
                {props.isMobile && <MobileActionBtns />}
                {props.isMobile && <MobileBtnsPanel showPlot={showPlot} revertToPlot={revertToPlot} toggleNarrative={toggleShowPlot}/>}
                {showPlot && <Plot isMobile={props.isMobile} />}
                {!showPlot && <MobileNarrative />}
                {!props.isMobile && <ActionBtnsPanel />}
                <Timeline isMobile={props.isMobile}/>
                {props.isMobile && <MobileSocials />}
                <Footer isMobile={props.isMobile}/>
            </Container>
        )
}
