import {
    connect
} from 'react-redux'
import Narratives from '../components/Narratives'
import { selectedCountriesSelector } from '../selectors/narratives-selector'

export default connect(state => {
    return {
        selectedView: state.viewfilter.selectedView,
        data: selectedCountriesSelector(state)
    }
}, {})(Narratives)