import { Selections } from '../constants/actionTypes';

const {
    SELECTIONS_DATA_LOADED,
    UPDATE_HIGHLIGHTED,
    RESET_HIGHLIGHTED,
    TOGGLE_ALL_CATEGORY,
    UPDATE_CRITICAL_UNCERTAINTIES,
    UPDATE_NEED_FOR_ACTION
} = Selections;

export const initialState = {
    data: null,
    criticalNeeds2019: null,
    highlighted: {},
    categoryKeys: {},
    criticalUncertainties: true,
    needForAction: true
};

export default function(state = initialState, { type, payload }) {
    switch (type) {
        case SELECTIONS_DATA_LOADED: {
            const { data, criticalNeeds2019} = payload
            return {
                ...state,
                data, 
                criticalNeeds2019
            };
        }
        case UPDATE_HIGHLIGHTED: {
            if(payload) {
                let update
                if(Array.isArray(payload)) {
                    update = Object.assign( ...payload.map( f => ({[f]: !state.highlighted[f]}) ) )
                } else {
                    update = {
                        [payload]: !state.highlighted[payload]
                    }
                }
                return {
                    ...state,
                    highlighted: {...state.highlighted, ...update}
                };
            }
            else {
                return {
                    ...state
                }
            }
        }
        case RESET_HIGHLIGHTED: {
            return {
                ...state,
                highlighted: {},
                categoryKeys: {},
                criticalUncertainties: true,
                needForAction: true
            };
        }
        case TOGGLE_ALL_CATEGORY: {
            const { key, show, children } = payload
            if(Array.isArray(children) && children.length>0) {
                const updateChildren = Object.assign( ...children.map( f => ({[f]: show}) ) )
                const updateKey = {[key]:show}
                return {
                    ...state,
                    categoryKeys: {...state.categoryKeys, ...updateKey},
                    highlighted: {...state.highlighted, ...updateChildren}
                };
            } else {
                return {
                    ...state
                };
            }
        }
        case UPDATE_CRITICAL_UNCERTAINTIES: {
            return {
                ...state,
                criticalUncertainties: !state.criticalUncertainties
            };
        }
        case UPDATE_NEED_FOR_ACTION: {
            return {
                ...state,
                needForAction: !state.needForAction
            };
        }
        default:
            return state;
    }
}
