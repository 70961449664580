import React, {
    PureComponent
} from 'react'
import { select } from 'd3-selection'
import { transition } from 'd3-transition'
import { easeCubicOut } from 'd3-ease'

class SelectedCircles extends PureComponent {

    state = {
        g: null
    }

    onRef = ref => this.setState({ g: select(ref) }, () => this.renderCircles())

    componentDidUpdate = () => this.renderCircles()

    click = ({ id }) => this.props.updateHighlighted(id)

    renderCircles = () => {
        const { g } = this.state
        const { data, scales:{ xScale, yScale, rSize }} = this.props
        const t = transition()
            .duration(1000)
            .ease(easeCubicOut)
        // rejoin the data and add the issues
        const issuesCircles = g.selectAll('.issues-selected-circles')
            .data(data, d => d.id)
        
        issuesCircles
            .enter()
        .append('circle')
            .classed('issues-selected-circles', true)
            .attr('cx', d => xScale(d.I))
            .attr('cy', d => yScale(d.U))
            .attr('r', d => rSize)
            .attr('fill', d => d.fill)
            .attr('stroke', d => d.stroke)
            .attr('stroke-width', '3px')
            .style('cursor', 'pointer')
            .on('click', this.click)
        
        // update the elements
        g.selectAll('.issues-selected-circles')
            .attr('fill', d => d.fill)
            .attr('stroke', d => d.stroke)
            .attr('stroke-width', '3px')
        .transition(t).delay(d => d.i * 20)
            .attr('cx', d => xScale(d.I))
            .attr('cy', d => yScale(d.U))            
            .attr('r', d => rSize)
        
        // remove anything that shouldnt be there
        issuesCircles
            .exit()
            .remove()
    }

    render() {
        return (
            <g ref={this.onRef} ></g>
        )
    }
}

export default SelectedCircles
