import {
    createSelector
} from 'reselect'

const countrySelector = state => state.viewfilter.countries
const regionsSelector = state => state.viewfilter.regions
const selectedCountrySelector = state => state.viewfilter.selectedCountry
const selectedRegionSelector = state => state.viewfilter.selectedRegion

// these regions were added later and do not have any countries related to them
const ignoreRegions = ['SET100', 'FEL100', 'Consumer', 'Government', 'Oil & Gas', 'Renewables', 'Nuclear', 'Utilities']

// these countries have conflicting ids between the WEC country list and the d3 map list
const replacements = {
    "Congo (Democratic Republic of)": ['Democratic Republic of the Congo'],
    "Cote d'Ivoire": ['Ivory Coast'],
    "Guinea-Bissau": ['Guinea Bissau'],
    "Russian Federation": ["Russia"],
    "Trinidad & Tobago": ['Trinidad and Tobago'],
    "United Kingdom": ['England', 'Ireland'],
    "United States": ['USA']
}

const formatCountries = countries => {
    const replaceCountries = countries.filter(d => replacements[d]).map(d => replacements[d]).reduce((acc, val) => [...acc, ...val], [])
    if (replaceCountries.length > 0) {
        return Object.assign(...[...countries, ...replaceCountries].map(d => ({
            [d]: true
        })))
    } else {
        return Object.assign(...[...countries].map(d => ({
            [d]: true
        })))
    }
}

export const selectedCountriesSelector = createSelector(
    countrySelector,
    regionsSelector,
    selectedCountrySelector,
    selectedRegionSelector,
    (countries, regions, selectedCountry, selectedRegion) => {
        if (countries && regions && ignoreRegions.indexOf(selectedRegion) < 0) {
            if (selectedCountry === 'None') {
                return formatCountries(countries.filter(f => f.region === selectedRegion).map(f => f.country))
            } else {
                return formatCountries([selectedCountry])
            }
        } else {
            return {}
        }
    }
)