import React, {
    PureComponent
} from 'react'
import styled from '@emotion/styled'
import { select } from 'd3-selection'
import { geoPath } from 'd3-geo'
import { geoNaturalEarth } from 'd3-geo-projection'

const Container = styled('div')`
    margin: 10px;
    margin-left:20px;
`

const HEIGHT = 130 
const WIDTH = 270

class WorldMap extends PureComponent {
    state = {
        svg: null
    }

    onRef = ref => {
        this.setState({ svg: select(ref) }, () => this.renderWorld())
    }

    componentDidUpdate = () => this.renderWorld()

    renderWorld = () => {
        const { countries, world } = this.props
        if(countries && world) {
            this.paintMap()
        }
    }
    
    paintMap = () => {
        const { countries, world } = this.props
        const { svg } = this.state
        const projection = geoNaturalEarth()
            .scale(55)
            .translate([WIDTH/2, HEIGHT/1.75])
        const path = geoPath().projection(projection)
        
        svg.selectAll('.countries-topo')
            .data(world.features)
        .enter().append("path")
            .attr("d", path)
            .classed('countries-topo', true)
            .style('stroke', 'white')
            .style('stroke-width', 0.2)
            .style("opacity",1)

        svg.selectAll('.countries-topo')
            .style("fill", d => countries[d.properties.name] ? '#00558B' : '#b2b2b2' )

    }

    render() {
        return (
            <Container height={HEIGHT} width={WIDTH} >
                <svg height={HEIGHT} width={WIDTH} >
                    <g ref={this.onRef} ></g>
                </svg>
            </Container>
            
        )
    }
}

export default WorldMap;


// bromley Equirectangular (Plate Carrée) Natural Earth