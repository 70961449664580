import React, { PureComponent } from "react";
import styled from "@emotion/styled";
import { min } from 'd3-array'

import ResponsiveWrapper from "./responsive-wrapper";
import Axes from "./axes";
import Annotations from "./annotations-div";
import Circles from "./circles";
import Lines from "./lines";
import SelectedCircles from "./selected-circles";
import SelectedCirclesText from "./selected-circles-text-div";
import Tooltip from "./tooltip-div";
import NoDataDiv from './no-data-div.js'

const Container= styled("div")`
  display:flex;
  align-items: flex-end;
  width:100%;
`;
const InnerContainer = styled("div")`
  position: relative;
  left: 0;
  top:0;
  width: ${props => props.width}px;
  height: ${props => props.height}px;
`;
const DivContainer = styled("div")`
  position: absolute;
  left: 0;
  top: 0;
  pointer-events: none;
  width: ${props => props.width}px;
  height: ${props => props.height}px;
`;
class IssuesChart extends PureComponent {
  state = {
    target: null
  };

  onMouseOver = target => this.setState({ ...this.state, target });

  onMouseOut = () => this.setState({ ...this.state, target: null });

  render() {
    const { target } = this.state;
    const {
      data,
      selectedData,
      lineData,
      updateHighlighted,
      highlighted,
      years,
      width,
      height,
      scales,
      margins,
      criticalUncertainties,
      needForAction
    } = this.props;

    return (
      <Container>
        <InnerContainer width={width} height={height}>
          <svg width={width} height={height}>
            <Axes
              scales={scales}
              margins={margins}
              width={width}
              height={height}
            />
            <Circles
              data={data}
              scales={scales}
              highlighted={highlighted}
              updateHighlighted={updateHighlighted}
              onMouseOver={this.onMouseOver}
              onMouseOut={this.onMouseOut}
            />
            <Lines data={lineData} scales={scales} years={years} />
            <SelectedCircles
              data={selectedData}
              scales={scales}
              updateHighlighted={updateHighlighted}
            />
          </svg>
          <DivContainer width={width} height={height}>
            <Annotations 
              margins={margins} 
              width={width} 
              height={height} 
              criticalUncertainties={criticalUncertainties}
              needForAction={needForAction}    
          />
            <SelectedCirclesText
              data={selectedData}
              scales={scales}
              width={width}
              height={height}
            />
            {target && <Tooltip scales={scales} target={target} />}
            {data.length ===0 && <NoDataDiv width={width} height={height} year={min(years)}/>}
          </DivContainer>
        </InnerContainer>
      </Container>
    );
  }
}
export default ResponsiveWrapper(IssuesChart);
