import { max, min } from 'd3-array'
import { nest } from 'd3-collection'
import { createSelector } from 'reselect'
import { uniq } from 'lodash/array'

import { findTop5Critical, findTop5Needs } from '../utils/critical-uncertainties'
import theme from '../constants/theme'
const { orange, blue } = theme

const yearsSelector = state => state.timeline.selectedYears
const dataSelector = state => state.plot.data
const viewfilterSelector = state => state.viewfilter.selectedView
const highlightedSelector = state => state.selections.highlighted
const criticalSelector = state => state.selections.criticalUncertainties
const needSelector = state => state.selections.needForAction

export const pointsSelector = createSelector(
    yearsSelector,
    dataSelector,
    viewfilterSelector,
    (years, data, viewfilter) => data?  getCleanData(data, years, viewfilter): null
)

export const selectedPointsSelector = createSelector(
    pointsSelector,
    highlightedSelector,
    criticalSelector,
    needSelector,
    (data, highlighted, critical, needs) => data?  getSelectedCleanData(data, highlighted, critical, needs): null
)

export const selectedLineSelector = createSelector(
    yearsSelector,
    dataSelector,
    viewfilterSelector,
    highlightedSelector,
    (years, data, viewfilter, highlighted) => data?  getSelectedCleanLines(data, years, viewfilter, highlighted): null
)

export const uniqueYearsSelector = createSelector(
    yearsSelector,
    (years) => years ? uniq(years) : null
)

export const uniqueCategoriesSelector = createSelector(
    pointsSelector,
    (data) => data ? uniq(data.map(f => f.name.split(' ').join('.').toLowerCase())) : null
)


// do the selection but also clean it in case there is any missing data...
function getCleanData(data, years, viewfilter) {
    if(data && Array.isArray(data) && years && Array.isArray(years)) {
        const minYear = min(years)
        const selected = data.filter( d => minYear === +d.Year && d.Country === viewfilter)
        const cleaned = selected.length > 0 ? cleanData(selected[0]) : []
        return cleaned
    } else {
        return []
    }
}

function getSelectedCleanData(data, highlighted, critical, needs) {
    // shoehorn in the critical uncertainties and need for action highlight
    if((critical || needs) && data && data.length>0) {
        return findCriticalNeedsData(data, highlighted, critical, needs)
    } else {
        // sorting is important for making the transitions line up using 'i'
        // add the simple fill as no critical needs have been highlighted
        return data.map( (d, i) => ({...d, i, fill:"#fff", stroke:blue}) ).filter( d => highlighted[d.id] )
    }
}

function findCriticalNeedsData(data, highlighted, critical, needs) {
    let combinedHighlight, criticalHighlighted, needsHighlighted
    // get list of critical uncertainties that need to be highlighted
    criticalHighlighted = critical? findTop5Critical(data, {I:10, U:10}) : {}
    // get list of need for actions that need to be highlighted
    needsHighlighted = needs? findTop5Needs(data, {I:10, U:-10}) : {}
    // combine them all for filtering
    combinedHighlight = {...highlighted, ...criticalHighlighted, ...needsHighlighted}
    return data.map( (d, i) => {
            const lowercaseID = d.id.toLowerCase()
            // add the fill color depending on which highlight group they belong to
            const fill = (!criticalHighlighted[lowercaseID] && !needsHighlighted[lowercaseID]) 
            ? '#fff' 
            : criticalHighlighted[lowercaseID] 
            ?  orange : blue
            // add stroke color depending on which highlight group they belong to
            const stroke = highlighted[lowercaseID]? blue : '#fff'
            return {...d, i, fill, stroke}
        })
        .filter( d => combinedHighlight[d.id.toLowerCase()] ) 
}


// same for the lines data, but restructure it to group multiple years
function getSelectedCleanLines(data, years, viewfilter, highlighted) {

    if( years[0] === years[1] ) return []

    const maxYear = max(years)
    const minYear = min(years)
    const selected = data.filter( d => +d.Year<=maxYear && +d.Year>=minYear && d.Country === viewfilter)
    let allIssues = []

    selected.forEach( item => {
        const { Year, issues } = item
        const mappedIssues = issues.map( d => ({...d, Year:+Year })).filter( d=> d.I && highlighted[d.id])

        allIssues.push(...mappedIssues)
    });
    const issueLines = nest()
        .key( d => d.id)
        .rollup( v => v.sort( (a,b) => a.Year-b.Year))
        .entries(allIssues)

    return issueLines    
}

// remove any issues that are I: 0 U: 0 (aka empty)
function cleanData(data) {
    return data.issues.filter(d => d.I || d.U)
}
