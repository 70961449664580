import React from 'react'
import styled from '@emotion/styled'

import { withStyles } from '@material-ui/core/styles'
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import FormControl from '@material-ui/core/FormControl'
import Select from '@material-ui/core/Select'

const Container = styled('div')`
    flex-grow: 1;
`
const styles = theme => ({
    root: {
        display: 'flex',
        flexWrap: 'wrap',
        alignItems: 'stretch'
    },
    formControl: {
        margin: theme.spacing.unit,
        display: 'flex',
        flex: 1,
        borderRadius: 3
    },
    selectEmpty: {
        marginTop: theme.spacing.unit * 2,
    }
})

const ViewSelect = ({ classes, name, views, selected, handleChange }) => {

    const change = event => handleChange(event.target.value)
    
    return (
        <Container>
            <FormControl className={classes.formControl}>
                <InputLabel htmlFor="view-dropdown">Select by {name}</InputLabel>
                <Select
                    value={selected}
                    onChange={change}
                    inputProps={{
                        name: 'view',
                        id: 'view-dropdown',
                    }}
                >
                    { views.map( (d,i)=> <MenuItem key={i} value={d.code}>{d.name}</MenuItem>) }
                </Select>
            </FormControl>
        </Container>
    )
}

export default withStyles(styles)(ViewSelect)