import { Narratives } from '../constants/actionTypes'

const {
    NARRATIVE_DATA_LOADED   
} = Narratives

export const initialState = {
    data: false
}

export default function(state = initialState, { type, payload }) {
    switch (type) {
        case NARRATIVE_DATA_LOADED: {
            return {
                ...state,
                data: payload
            }
        }
        default:
            return state
    }
}
