import { Timeline } from '../constants/actionTypes';

import config from '../config'

const {
    TIMELINE_DATA_LOADED,
    UPDATE_SELECTED_YEARS
} = Timeline;

export const initialState = {
    data: false,
    selectedYears: config.timeline.years
};

export default function(state = initialState, { type, payload }) {
    switch (type) {
        case TIMELINE_DATA_LOADED: {
            return {
                ...state,
                data: payload
            };
        }
        case UPDATE_SELECTED_YEARS: {
            return {
                ...state,
                selectedYears: payload
            };
        }
        default:
            return state;
    }
}
