import React from 'react'
import styled from '@emotion/styled'

const Container = styled('div')`
    padding-left: ${props => props.paddingLeft};
    padding-top: ${props => props.paddingTop};
    width: 100%;
    & h5 {
        font-weight: bold;
        margin:0;
        font-size: 16px
    }
    & p {
        margin:0;
        font-size:14px;
    }
`

const PlotTitle = ({size, years, view, isMobile}) => {
    let paddingLeft = '60px'
    let paddingTop = '0'
    if(isMobile) {
        paddingLeft = '20px'
        paddingTop = '50px'
    }
    return (
        <Container paddingLeft={paddingLeft} paddingTop={paddingTop}>
            <h5>World Energy Issues Monitor | {years.join('-')} | {view} </h5>
        </Container>
    )
}

export default PlotTitle;