import {
  connect
} from 'react-redux'
import MobileActionBtns from '../components/MobileActionBtns'
import { updateCriticalUncertainties ,updateNeedForAction } from '../actions/selections'
export default connect(state => {
  return {
      criticalUncertainties: state.selections.criticalUncertainties,
      needForAction: state.selections.needForAction
  }
}, {
  updateCriticalUncertainties ,updateNeedForAction
})(MobileActionBtns)