import { Map } from '../constants/actionTypes';

const {
    MAP_DATA_LOADED   
} = Map;

export const initialState = {
    world: false
};

export default function(state = initialState, { type, payload }) {
    switch (type) {
        case MAP_DATA_LOADED: {
            return {
                ...state,
                world: payload
            };
        }
        default:
            return state;
    }
}
