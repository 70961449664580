import { connect } from 'react-redux';
import Map from '../components/Map';

import { selectedCountriesSelector } from '../selectors/map-selector'

export default connect(state => {
    return {
        world: state.map.world,
        countries: selectedCountriesSelector(state),
    };
}, {})(Map);
