export const DataLoader = {
    GET_DATA: 'GET_DATA',
    GET_DATA_SUCCESS: 'GET_DATA_SUCCESS',
    GET_DATA_FAILURE: 'GET_DATA_FAILURE'
}
export const Plot = {
    PLOT_DATA_LOADED: 'PLOT_DATA_LOADED'
}
export const Timeline = {
    TIMELINE_DATA_LOADED: 'TIMELINE_DATA_LOADED',
    UPDATE_SELECTED_YEARS: 'UPDATE_SELECTED_YEARS'
}
export const Viewfilter = {
    VIEWFILTER_DATA_LOADED: 'VIEWFILTER_DATA_LOADED',
    SET_REGION_SELECTED_VIEW: 'SET_REGION_SELECTED_VIEW',
    SET_COUNTRY_SELECTED_VIEW: 'SET_COUNTRY_SELECTED_VIEW'
}
export const Selections = {
    SELECTIONS_DATA_LOADED: 'SELECTIONS_DATA_LOADED',
    UPDATE_HIGHLIGHTED: 'UPDATE_HIGHLIGHTED',
    RESET_HIGHLIGHTED: 'RESET_HIGHLIGHTED',
    TOGGLE_ALL_CATEGORY: 'TOGGLE_ALL_CATEGORY',
    UPDATE_CRITICAL_UNCERTAINTIES: 'UPDATE_CRITICAL_UNCERTAINTIES',
    UPDATE_NEED_FOR_ACTION: 'UPDATE_NEED_FOR_ACTION'
}
export const Map = {
    MAP_DATA_LOADED: 'MAP_DATA_LOADED'
}
export const Narratives = {
    NARRATIVE_DATA_LOADED: 'NARRATIVE_DATA_LOADED'
}
