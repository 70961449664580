import React from 'react'
import styled from '@emotion/styled'
const Container = styled('div')`
    position:absolute;
    left:0;    
    top: 50%;
    width:100%;
    padding: 10px 60px;
`
const HelpText = styled('div')`
    font-size: 16px;
    font-weight: 400;
    color: #8a8a8a;
    text-align: center;
`
const ErrorMessage = () => {
    return (
        <Container>
            <HelpText>Application failed to load, please try again using a modern browser</HelpText>
        </Container>
    )
}

export default ErrorMessage;
