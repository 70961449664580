import {
  connect
} from 'react-redux'
import MobileBtnsPanel from '../components/MobileBtnsPanel'
import { resetAll } from '../actions/action-btn-panel'

export default connect(state => {
  return {
  }
}, {
  resetAll
})(MobileBtnsPanel)