import React from 'react'
import { line, curveCardinal } from 'd3-shape'
import './index.css'
const Lines = ({ data, years, scales: { xScale, yScale } }) => {

    const path = line()
        .curve(curveCardinal.tension(0.1))
        .x( d => xScale(d.I))
        .y( d => yScale(d.U))
        
    const generatePath = () =>  {
        return data.map( (d, i) => {
            return (
                <path 
                    d={path(d.value)}
                    key={i}
                    strokeOpacity={0.6}
                    stroke="#00558B" 
                    strokeWidth={1}
                    className={'line-path'}
                    fill={'transparent'}
                    pointerEvents={'none'}
                />
            )
        })
    }
    
    const generateVertices = () => {
        return data.map( v => {
            return v.value.map( (p, i) => {
                const cx = xScale(p.I)
                const cy = yScale(p.U)
                return (
                    <circle                         
                        key={i}
                        cx={cx}
                        cy={cy}
                        r={2}
                        opacity={.6}
                        fill={'#00558B'}
                        className={'line-circle'}
                        pointerEvents={'none'}
                    />
                )
            })
        })
    }

    const generateLabels = () => {
        return data.map( v => {
            return v.value.map( (p, i) => {
                const { Year, I, U } = p
                const x = xScale(I)
                const y = yScale(U)
                const dy = 10
                const text = Year === years[0]? '' : Year
                return (
                    <text                         
                        key={i}
                        x={x}
                        y={y}
                        dy={dy}
                        textAnchor= {'middle'}
                        fontFamily={ 'BrandonText'}
                        className={'line-label'}
                        fill={'#5c5c5c'}
                        fontSize={'10px'}
                    >
                    {text}
                    </text>
                )
            })
        })
    }

    return (
        <g> 
            { generatePath() }
            { generateVertices() }
            { generateLabels() }
        </g>
    )
}
export default Lines
