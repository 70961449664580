import React, { PureComponent } from 'react'
import styled from '@emotion/styled'
import { gaEvent } from '../../utils/tracking'

import Timeslider from './timeslider'
import PlayButton from './play-button'

const Container = styled('div')`
    padding-left: ${props => props.paddingLeft};
    padding-right:10px;
    display:flex;
`

class Timeline extends PureComponent {
    state = {
        play:false,
        allyears: [2015,2016,2017,2018,2019,2020,2021,2022]
    }
    counter = 0
    total = 0
    originallySelectedYears = []

    update = years => {
        gaEvent('Timeline', 'Year')
        // clear the old selection when the user changes the range manually
        this.originallySelectedYears = years
        this.props.updateSelectedYears(years)
    }
    updatePlay = () => {
        gaEvent('Timeline', 'Play')
        this.setState({play:!this.state.play }, () => {
            if(this.state.play) {
                if(this.originallySelectedYears.length === 0) {
                    this.originallySelectedYears = this.props.selectedYears
                }
                this.counter = this.getEndYear() - this.getStartYear()
                this.total = this.getEndYear() - this.getStartYear()
                this.cycleYears()
            } else {
                this.stopCycleYears()
            }
        })
    }
    cycleYears = () => {
        if(this.state.play) {
            if(this.counter>=0){
                this.props.updateSelectedYears([this.getEndYear() - this.counter, this.getEndYear()])
                this.counter = this.counter - 1
                setTimeout(this.cycleYears, 1500)
            } else {
                this.stopCycleYears()
            }
        }
    }
    stopCycleYears = () => {
        this.setState({play:false })
        // if the user has finished the animation, return the timeline to the original selection
        if (this.counter + 1 <= 0 ) {
            this.props.updateSelectedYears([this.originallySelectedYears[0], this.originallySelectedYears[1]])
            this.counter = 0
            this.total = 0
            this.originallySelectedYears = []
        } else {
            // if the animation is paused but not finished, allow the animation to resume when the user hits play again
            this.props.updateSelectedYears([this.getEndYear() - this.counter - 1, this.getEndYear()])
        }        
    }
    // if the user has no range selected and it is the last year in the tool, 
    // then play the full timeline when they hit play
    getStartYear = () => {
        const { selectedYears } = this.props
        let startDate = selectedYears[0]
        if(selectedYears[0] === this.state.allyears[this.state.allyears.length - 1]) {
            startDate = this.state.allyears[0];
        }
        return startDate
    }
    // if the user has no range selected, play timeline from that year onwards
    getEndYear = () => {
        const { selectedYears } = this.props
        let endDate = selectedYears[1]
        if(selectedYears[1] === selectedYears[0]) {
            endDate = this.state.allyears[this.state.allyears.length - 1]
        }
        return endDate
    } 

    render() {
        const { data, selectedYears, isMobile } = this.props
        const { play, allyears } = this.state
        let paddingLeft = '60px'
        if(isMobile) {
            paddingLeft = '20px'
        }
        if(!data) return null
        return (
            <Container paddingLeft={paddingLeft}>
                <PlayButton play={play} updatePlay={this.updatePlay}/>
                <Timeslider
                    data={allyears}
                    selectedYears={selectedYears}
                    update={this.update}
                />
            </Container>
        )
    }
}

export default Timeline
