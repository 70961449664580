import React, { Component } from "react";
import styled from "@emotion/styled";

import HighlightCheckboxes from '../ActionBtnsPanel/highlight-checkboxes'

const Container = styled("div")`
  display: flex;
  flex-direction: row;
  padding: 0 20px;
  flex-wrap: nowrap;
`;

class MobileActionBtns extends Component {
  render() {
    const {
      updateCriticalUncertainties,
      updateNeedForAction,
      criticalUncertainties,
      needForAction
    } = this.props;

    return (
      <Container>
        <HighlightCheckboxes 
            updateCriticalUncertainties={updateCriticalUncertainties}
            updateNeedForAction={updateNeedForAction}
            criticalUncertainties={criticalUncertainties}
            needForAction={needForAction}
            isMobile={true}
        />
      </Container>
    );
  }
}

export default MobileActionBtns;
