import {
    Viewfilter
} from '../constants/actionTypes';

const {
    VIEWFILTER_DATA_LOADED,
    SET_REGION_SELECTED_VIEW,
    SET_COUNTRY_SELECTED_VIEW
} = Viewfilter;

export const initialState = {
    regions: null,
    countries: null,
    selectedRegion: 'Global',
    selectedCountry: 'None',
    selectedView: 'Global'
};

export default function (state = initialState, {
    type,
    payload
}) {
    switch (type) {
        case VIEWFILTER_DATA_LOADED:
            {  
                const {
                    countries,
                    regions
                } = payload
                return {
                    ...state,
                    countries,
                    regions
                };
            }
        case SET_REGION_SELECTED_VIEW:
            {                
                return {
                    ...state,
                    selectedRegion: payload,
                    selectedCountry: 'None',
                    selectedView: payload
                };
            }
        case SET_COUNTRY_SELECTED_VIEW:
            {
                const selectedView = payload === 'None' ? state.selectedRegion : payload
                return {
                    ...state,
                    selectedCountry: payload,
                    selectedView: selectedView
                };
            }
        default:
            return state;
    }
}