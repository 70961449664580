
import React from 'react'
import styled from '@emotion/styled'
import { withStyles } from '@material-ui/core/styles'
import Button from '@material-ui/core/Button'

const Container = styled('div')`
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 0 40px;
    height: calc(100% - 50px);
`
const Narrative = styled('div')`
    font-weight: 400;
    font-size: 14px;
    color: #333;
    text-align: center;
`

const styles = theme => ({
  button: {
      border: 'solid 1px #b2b2b2',
      backgroundColor: '#fff',
      borderRadius: 3,
      textTransform: 'capitalize',
      '&:hover': {
          backgroundColor: '#eee'
      } 
  },    
})

const MobileNarrative = ({ classes, selectedView, data }) => {
  const selectedNarrative = data[selectedView]
  const isBtnDisabled = !selectedNarrative || selectedNarrative.pdf === ''
  const addNewlines = (excerpt) => {
    const lines = excerpt.split('\n,')
    return lines.map(d => <p key={d}>{d}</p> );
  }
  const downloadNarrative = () => window.open(`${process.env.PUBLIC_URL}/pdfs/${selectedNarrative.pdf}.pdf`)
  return (
        <Container> 
          {selectedNarrative && <Narrative>{addNewlines(selectedNarrative.excerpt)}</Narrative>}
          <Button disabled={isBtnDisabled} onClick={downloadNarrative} className={classes.button}>Download Full Narrative here</Button>
        </Container>
    )
}

export default withStyles(styles)(MobileNarrative)
