import React ,{ PureComponent } from 'react'
import List from '@material-ui/core/List'
import ListItem from '@material-ui/core/ListItem'
import ListItemText from '@material-ui/core/ListItemText'
import Checkbox from '@material-ui/core/Checkbox'
import { withStyles } from '@material-ui/core/styles'

const styles = theme => ({
    root: {
        height:30,
        color: '#333'
    },
    defaultDisabled: {
        height:30,
        color: '#b2b2b2'
    },
    checked: {
        height:30,
        '&$checked': {
            color: '#00558B',
        },
    },
    listitem: {
        padding: 2
    }
});


class CategoryList extends PureComponent {

    click = id => () => this.props.update(id) 

    render() {
        const { data, uniqueCategories, highlighted, classes } = this.props
        return (
            <List>
                { data.map( item => {
                    const { category, alias } = item
                    const id = alias.split(' ').join('.').toLowerCase() // to make it correspond to data
                    const isDisabled = !uniqueCategories.includes(id)        
                    const isDisabledClass = isDisabled ? classes.defaultDisabled : classes.root
                    return (
                        <ListItem
                            key={alias}
                            role={undefined}
                            dense={true}
                            className={classes.listitem}
                        >
                            <Checkbox
                                classes={{
                                    root: isDisabledClass,
                                    checked: classes.checked,
                                }}
                                disabled={isDisabled}
                                checked={highlighted[id] ? true: false}
                                tabIndex={-1}
                                onChange={this.click(id)}
                            />
                            <ListItemText primary={category} />
                        </ListItem>
                    )
                })}
            </List>
        )
    }
}


export default withStyles(styles)(CategoryList)
