import { createSelector } from 'reselect'

const countriesSelector = state => state.viewfilter.countries
const regionsSelector = state => state.viewfilter.regions
const selectedRegionSelector = state => state.viewfilter.selectedRegion
const selectedViewSelector = state => state.viewfilter.selectedView

export const selectedCountriesSelector = createSelector(
    countriesSelector,
    selectedRegionSelector,
    (countries, selectedRegion) => {
        if(countries && selectedRegion) {
            const formatted = countries.filter(f=>f.region===selectedRegion).map(f=>({name:f.country, code:f.country}))
            formatted.sort((a, b) => a.code.localeCompare(b.code))
            const noCountry = {name: 'None', code: 'None'}
            formatted.unshift(noCountry)
            return formatted
        } else {
            return null
        }
    }
)

const codeLookupSelector = createSelector(
    countriesSelector,
    regionsSelector,
    (countries, regions) => {
        return Object.assign(...regions.map(d => ({[d.code]: d.name})))
    }
)

export const viewSelector = createSelector(
    codeLookupSelector,
    selectedViewSelector,
    (lookup, selectedView) => {
        return lookup.hasOwnProperty(selectedView) ? lookup[selectedView] : selectedView
    }
)