import {
    createStore,
    applyMiddleware,
    compose
} from 'redux'
import { composeWithDevTools } from 'redux-devtools-extension'
import rootReducer from '../reducers'
import thunk from 'redux-thunk'
import urlParamManager from './url-param-manager'


function composeForEnvironment() {
    if(process.env.NODE_ENV === 'production') {
        return compose(
            applyMiddleware(thunk),
            urlParamManager
        )
    } else {
        return composeWithDevTools(
            applyMiddleware(thunk),
            urlParamManager
        )
    }
}

export default function configureStore(initialState) {
    return createStore(
        rootReducer, 
        initialState,
        composeForEnvironment()
        )
}
