export default {
    dataurl: 'data',
    timeline: {
        years: [2022, 2022]
    },
    domains: {
        max: 10,
        min: -10,
        rmin: 1
    },
    ranges: {
        rMax: 20,
        rMin: 7
    }
}