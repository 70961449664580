import React from 'react'
import styled from '@emotion/styled'
const Container = styled('div')`
    width:100%;
    padding: ${props => props.padding};
`
const HelpText = styled('div')`
    font-size: 12px;
    font-weight: 400;
    color: #8a8a8a;
`
const Footer = ({isMobile}) => {
    let padding = '10px 60px'
    if(isMobile){
        padding = '10px 20px'
    }
    return (
        <Container padding={padding}>
            <HelpText>&copy; World Energy Council, powered by Arup</HelpText>
        </Container>
    )
}

export default Footer;
