import React from 'react'
import styled from '@emotion/styled'
import { gaEvent } from '../../utils/tracking';

import ViewSelect from './view-select'

const Container = styled('div')`
    flex: 2 0 auto;
    display: flex;
    flex-direction: row;
`
const ViewFilter = ({ regions, countries, selectedCountry, selectedRegion, selectedView, setRegionSelectedView, setCountrySelectedView }) => {

    const handleRegionChange = f => {
        gaEvent('Filter', 'Region Change', f)
        setRegionSelectedView(f)
    }
    const handleCountryChange = f => {
        gaEvent('Filter', 'Country Change', f)
        setCountrySelectedView(f)
    }
    
    return (
        <Container>
           { regions && 
            <ViewSelect 
                name={'Region'}
                views={regions}
                selected={selectedRegion}
                handleChange={handleRegionChange}
            /> }

            { countries && 
            <ViewSelect 
                name={'Country'}
                views={countries}
                selected={selectedCountry}
                handleChange={handleCountryChange}
            /> }
        </Container>
    )
}

export default ViewFilter