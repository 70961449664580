import {
    connect
} from 'react-redux'
import ActionBtnsPanel from '../components/ActionBtnsPanel'
import { resetAll } from '../actions/action-btn-panel'
import { updateCriticalUncertainties ,updateNeedForAction } from '../actions/selections'
import { viewSelector } from '../selectors/viewfilter-selector'
export default connect(state => {
    return {
        selectedRegion: viewSelector(state),
        selectedCountry: state.viewfilter.selectedCountry,
        criticalUncertainties: state.selections.criticalUncertainties,
        needForAction: state.selections.needForAction
    }
}, {
    resetAll, updateCriticalUncertainties ,updateNeedForAction
})(ActionBtnsPanel)