import React, { PureComponent } from "react";
import { withStyles } from '@material-ui/core/styles'
import styled from '@emotion/styled'

import { gaEvent } from '../../utils/tracking'

import ExpansionPanel from '@material-ui/core/ExpansionPanel';
import ExpansionPanelSummary from '@material-ui/core/ExpansionPanelSummary';
import ExpansionPanelDetails from '@material-ui/core/ExpansionPanelDetails';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import Checkbox from "@material-ui/core/Checkbox"

import CategoryList from "./CategoryList"

const HeaderLabel = styled('div')`
  flex: 1 1 0; 
  display:flex;
  align-items:center;
`

const styles = theme => ({
  root: {
    color: "#333",
    marginRight: 5,
    padding:8
  },
  checked: {
    "&$checked": {
      color: "#00558B"
    }
  },
  content: {
    margin:3
  },
  expandIcon: {
    right: 3
  },
  heading: {
    fontSize: 14,
    padding: "0 5px 0 10px",
    backgroundColor: "#eee",
  },
  expanded: {
    fontSize: 14,
    padding: "0 5px 0 10px"
  },
  panel: {
    border: "solid 1px #fff",
    boxShadow: "none",
    "&::before": {
      backgroundColor: "transparent"
    }
  }
});

class CategoryPanel extends PureComponent {
  toggleAll = event => {
    event.stopPropagation(); // prevent the panel from expanding
    const show = event.target.checked;
    const { data, uniqueCategories } = this.props
    const { key, values } = data
    if(data && uniqueCategories) {
        // mimic the formatting in the category list
        // ignore any categories that arent included in the unique list
        // ... basically the disabled checkboxes in the categorylist
        const children = values
        .map(f =>
            f.alias
            .split(" ")
            .join(".")
            .toLowerCase()
        )
        .filter(f => uniqueCategories.includes(f));
        this.props.toggleAllCategory({ show, children, key });
    }
  };

  update = id => {
    gaEvent('List', 'Issue', id)
    this.props.updateHighlighted(id)
  };

  render() {
    const { data, uniqueCategories, highlighted, categoryKeys, classes } = this.props;
    const { key, values } = data;
    return (
      <ExpansionPanel className={classes.panel} key={key}>
        <ExpansionPanelSummary
          classes={{
            root: classes.heading,
            content: classes.content,
            expanded: classes.expanded,
            expandIcon: classes.expandIcon
          }}
          expandIcon={<ExpandMoreIcon />}
        >
          <HeaderLabel>{key}</HeaderLabel>
          <Checkbox
            classes={{
              root: classes.root,
              checked: classes.checked
            }}
            checked={categoryKeys[key] ? true: false}
            disableRipple={true}
            onClick={this.toggleAll}
          />
        </ExpansionPanelSummary>
        <ExpansionPanelDetails>
          <CategoryList
            uniqueCategories={uniqueCategories}
            highlighted={highlighted}
            update={this.update}
            data={values}
          />
        </ExpansionPanelDetails>
      </ExpansionPanel>
    );
  }
}

export default withStyles(styles)(CategoryPanel);
