import { connect } from 'react-redux';
import Loading from '../components/Loading';

export default connect(state => {
    return {
        isLoading: state.dataloader.fetching,
        loadingSuccess: state.dataloader.success,
        loadingError: state.dataloader.error
    };
}, {})(Loading);
