// initialize bugsnag ASAP, before other imports
import bugsnag from 'bugsnag-js'
import createPlugin from 'bugsnag-react'
import React from 'react'
import ReactDOM from 'react-dom'

import { Provider } from 'react-redux'
import configureStore from './store/configureStore.js'
import App from './connected/App'
import ErrorMessage from './components/ErrorMessage'

import { addPolyfills } from './utils/polyfills'

import { loadData } from './actions/data-loader'

const bugsnagClient = bugsnag({
    apiKey: '02502e37ce1d10f06c63574ea298d430',
    autoCaptureSessions: true,
    notifyReleaseStages: ['production'],
});

bugsnagClient.metaData = {
    project: 'World Energy Council Issues Monitor',
};

const ErrorBoundary = bugsnagClient.use(createPlugin(React));

// add fixes to IE and other polyfills and fallback to not loading if missing key items
addPolyfills() 
const supportsPromise = typeof Promise !== "undefined" && Promise.toString().indexOf("[native code]") !== -1
const supportsFetch = 'fetch' in window
const load = supportsPromise && supportsFetch

const store = configureStore()

// dont load if it doesn't support Promises, fetch, IE etc
if(load) {
    store.dispatch(loadData())
}

ReactDOM.render(
    <ErrorBoundary>
        <Provider store={store}>
            {load && <App />}
            {!load && <ErrorMessage />}
        </Provider>
    </ErrorBoundary>,
    document.getElementById('root')
)