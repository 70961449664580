import React, {
    Component
} from 'react'
import { withStyles } from '@material-ui/core/styles'
import Button from '@material-ui/core/Button'
import NarrativeDialog from './narrative-dialog'
import { gaEvent } from '../../utils/tracking'

import theme from '../../constants/theme'
const { orange } = theme

const styles = theme => ({
    button: {
        backgroundColor: orange,
        color: 'white',
        width: 360,
        borderRadius: 3,
        textTransform: 'capitalize',
        fontWeight: 700,
        '&:hover': {
            backgroundColor: orange,
            opacity: '70%'
        }
    },
    buttonDisabled: {
        border: 'solid 1px #b2b2b2',
        backgroundColor: '#fff',
        width: 360,
        borderRadius: 3,
        textTransform: 'capitalize',
        fontWeight: 700,
    }
})

class Narratives extends Component {

    state = {
        open: false
    }

    handleClose = () => {
        this.setState({
            ...this.state,
            open: false
        })
    }

    handleToggle = () => {
        this.setState({
            ...this.state,
            open: !this.state.open
        })
        gaEvent('Narrative', 'Open Narrative')
    }

    downloadNarrative = location => window.open(`${process.env.PUBLIC_URL}/pdfs/${location}.pdf`)

    render() {
        const { classes, selectedView, data } = this.props
        const { open } = this.state
        const isDisabled = !data[selectedView]
        const selectedNarrative = data[selectedView]
        const selectedViewPlace = isDisabled ? null : selectedNarrative.place

        return (
            <div>
                <Button disabled={isDisabled} onClick={this.handleToggle} className={isDisabled ? classes.buttonDisabled : classes.button}>View Narrative</Button>
                {!isDisabled && <NarrativeDialog
                    selectedNarrative={selectedNarrative}
                    selectedViewPlace={selectedViewPlace}
                    downloadNarrative={this.downloadNarrative}
                    handleClose={this.handleClose}
                    open={open}
                />}
            </div>
        )
    }
}

export default withStyles(styles)(Narratives)
