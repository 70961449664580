import React from "react"
import styled from '@emotion/styled'
import { withStyles } from '@material-ui/core/styles'
import MenuIcon from '@material-ui/icons/Menu'
import Button from '@material-ui/core/Button'
import Menu from '@material-ui/core/Menu'

import MobileNarrativeBtn from '../../connected/MobileNarrativeBtn'
import ResetBtn from '../ActionBtnsPanel/reset-btn'
import Selections from '../../connected/Selections'

const Container = styled('div')`
    padding: 20px;
    display: flex;
    flex-direction: row;
    flex-grow: 1;
    justify-content: space-between;
    align-items: center;
 
`

const StyledMenu = withStyles({
    paper: {
      border: '1px solid #d3d4d5',
    },
  })(props => (
    <Menu
      elevation={0}
      getContentAnchorEl={null}
      anchorOrigin={{
        vertical: 'bottom',
        horizontal: 'center',
      }}
      transformOrigin={{
        vertical: 'top',
        horizontal: 'center',
      }}
      {...props}
    />
  ));

export default function MobileBtnsPanel({
  resetAll,
  showPlot,
  revertToPlot,
  toggleNarrative
}) {
    const [anchorEl, setAnchorEl] = React.useState(null);

    const handleClick = event => {
        setAnchorEl(event.currentTarget)
    }

    const handleClose = () => {
        setAnchorEl(null)
    }

    const reset = () => {
      resetAll()
      revertToPlot()
    }

    return (
      <Container>
        <Button 
          size="small"
          variant="outlined"
          onClick={handleClick}
        >
          <MenuIcon/> Issues 
        </Button>
        <StyledMenu
          id="simple-menu"
          anchorEl={anchorEl}
          keepMounted
          open={Boolean(anchorEl)}
          onClose={handleClose}
        >
          <Selections/>
        </StyledMenu>
         
        
        <MobileNarrativeBtn showPlot={showPlot} toggleNarrative={toggleNarrative} />
        <ResetBtn resetAll={reset} isMobile={true}/>
      </Container>
    );
  }
