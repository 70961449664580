import { Viewfilter } from '../constants/actionTypes';

const {
    VIEWFILTER_DATA_LOADED,
    SET_REGION_SELECTED_VIEW,
    SET_COUNTRY_SELECTED_VIEW
} = Viewfilter;

export const viewfilterDataLoaded = payload => ({
    type: VIEWFILTER_DATA_LOADED,
    payload,
});

export const setRegionSelectedView = payload => ({
    type: SET_REGION_SELECTED_VIEW,
    payload,
});

export const setCountrySelectedView = payload => ({
    type: SET_COUNTRY_SELECTED_VIEW,
    payload,
});