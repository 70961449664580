import {
    DataLoader
} from '../constants/actionTypes';

const {
    GET_DATA,
    GET_DATA_SUCCESS,
    GET_DATA_FAILURE
} = DataLoader;

export const initialState = {
    fetching: false,
    success: false,
    error: false,
};

export default function (state = initialState, {
    type,
    payload
}) {
    switch (type) {
        case GET_DATA:
            {
                return {
                    ...state,
                    fetching: true,
                }
            }
        case GET_DATA_SUCCESS:
            {
                return {
                    ...state,
                    error: false,
                    success: true,
                    fetching: false,
                }
            }
        case GET_DATA_FAILURE:
            {
                return {
                    ...state,
                    error: true,
                    fetching: false,
                }
            }
        default:
            return state;
    }
}