import { Plot } from '../constants/actionTypes';

const {
    PLOT_DATA_LOADED
} = Plot;

export const initialState = {
    data: false
};

export default function(state = initialState, { type, payload }) {
    switch (type) {
        case PLOT_DATA_LOADED: {
            return {
                ...state,
                data: payload
            };
        }
        default:
            return state;
    }
}
