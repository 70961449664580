import { Plot } from '../constants/actionTypes';

const {
    PLOT_DATA_LOADED
} = Plot;

export const plotDataLoaded = payload => ({
    type: PLOT_DATA_LOADED,
    payload,
});
