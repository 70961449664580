
import React from 'react'
import PropTypes from 'prop-types'
import classNames from 'classnames'
import styled from '@emotion/styled'
import { withStyles } from '@material-ui/core/styles'
import Button from '@material-ui/core/Button'
import ClearIcon from '@material-ui/icons/Clear'
import { gaEvent } from '../../utils/tracking'

const styles = theme => ({
    button: {
        textTransform: 'capitalize',
        height: 30
    },
    leftIcon: {
        marginRight: theme.spacing.unit,
    },
    iconSmall: {
        fontSize: 20,
    }
})

const Container = styled('div')`
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    padding-left: ${props => props.padding};
    padding-right: ${props => props.padding};
`

const ResetBtn = ({ classes, resetAll, isMobile }) => {
    let padding = '10px'
    if(isMobile) {
        padding = 0
    }
    return (
        <Container padding={padding} > 
            <Button
                size="small"
                variant="outlined" 
                className={classes.button}
                onClick={() => {
                    gaEvent('General', 'Reset')
                    resetAll()
                }}
            >
                <ClearIcon className={classNames(classes.leftIcon, classes.iconSmall)} />
                Reset
            </Button>
        </Container>
    )
}

ResetBtn.propTypes = {
    resetAll: PropTypes.func.isRequired,
    classes: PropTypes.object.isRequired
}

export default withStyles(styles)(ResetBtn)
